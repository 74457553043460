import React from 'react'
import { useAccount, useConnect } from 'wagmi'

import { config as wagmiConfig } from '../components/web3ModalProvider'
import { OFAC_SANCTIONED_ADDRESSES } from '../utils/utils'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { onInvalidSessionFetch, onValidSessionFetch } from './siweService'
import { set } from 'ip3country/src/ip_supalite'
import { useDisconnect } from 'wagmi'
import { ConnectionController } from '@web3modal/core'
import { BACKEND_URL } from '../utils/utils'
import { useNavigate } from 'react-router-dom'


const fetchUserData = async (address) => {
  try {
    const res = await fetch(`${BACKEND_URL}/user/${address}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
    const userData = await res.json()
    console.log('fetch success', userData)
    return userData
  } catch (err) {
    console.error('fetchUserData err', err)
  }
}


export const useWeb3Provider = () => {
  const { connector } = useAccount(wagmiConfig)

  const getProvider = async () => {
    try {
      const provider = await connector.getProvider()
      console.log('getProvider', { provider })
      return provider
    } catch (err) {
      console.error('getProvider err', err)
    }
  }

  return {
    getProvider,
  }
}

// sometimes the wallet provider will auto-connect to the wallet, this will trigger the onAccountChange function,
// we want to make sure this only happens when the user actually clicks the connect button
let userBroken = false

export const useWeb3Open = () => {
  const { open: wagmiOpen } = useWeb3Modal(wagmiConfig)

  return {
    open: function () {
      userBroken = true
      wagmiOpen()
    },
  }
}

export const useWeb3Connect = () => {
  const { connect: wagmiConnect, connectors: wagmiConnectors } =
    useConnect(wagmiConfig)

  return {
    connect: function (args) {
      userBroken = true //maybe keep this as false as above
      wagmiConnect(args)
    },
    connectors: wagmiConnectors,
  }
}





export const useWeb3Service = () => {

  const [address, setAddress] = React.useState(null)
  const [currentUser, setCurrentUser] = React.useState(null)
  const [status, setStatus] = React.useState('logged-out')

  const navigate = useNavigate()


  React.useEffect(() => {
    if (currentUser && currentUser.displayName) {
      setStatus('logged-in')
    }
  }, [currentUser])


  onValidSessionFetch(async (session) => {
    if (session && session.address && (!currentUser || currentUser.address !== session.address)) {
      setAddress(session.address)
      const userData = await fetchUserData(session.address)

      if (userData && userData.displayName) {
        setCurrentUser(userData)
      } else {
        setStatus('new-user')
      }

    }

  })

  onInvalidSessionFetch(() => {
    if (currentUser || address || status === 'logged-in') {
      disconnect()
    }

  })

  const disconnect = async () => {
    ConnectionController.disconnect()
    // wagmiDisconnect()
    setAddress(null)
    setCurrentUser(null)
    setStatus('logged-out')
    navigate('/')
  }

  return {
    address,
    currentUser,
    setCurrentUser,
    status,
    disconnect,
  }
}
