import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Divider,
  Snackbar,
  Stack,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ImageIcon from '@mui/icons-material/Image'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AppsIcon from '@mui/icons-material/Apps'
import PersonIcon from '@mui/icons-material/Person'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import * as web3 from '../web3/web3'

import {
  TextField,
  InputAdornment,
  Checkbox,
  Button,
  Modal,
} from '@mui/material'
import { CustomSwitch } from './subcomponents/CustomSwitch'
import * as eventService from '../services/eventService'
import {
  convertIntTo2DecimalFloat,
  isCreator,
  generateCampaignLink,
  getPrettyDateString,
  EVENT_TYPES,
  getMyContributionAmount,
  BACKEND_URL,
  matchYoutubeURL,
} from '../utils/utils'
import styles from '../styles/ActiveEventComponent.module.css'
import { OptInModal } from './ActiveEventsTable'
import { generateProgressBarValue } from './subcomponents/progressBar'
import { fontSize, handleBreakpoints } from '@mui/system'
import { axiosInstance } from '../services/webClient'
import { FormattedEventUrl } from './subcomponents/FormattedEventUrl'
import { useWeb3Provider } from '../services/web3Service'

const calculateHoursUntilDeadlineOccurs = (event) => {
  if (!event) return null
  const deadline = new Date(event.deadline)
  const now = new Date()
  const diffInMilliseconds = deadline - now
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
  return diffInHours
}

export const EventHeaderComponent = (props) => {
  const { event, switchDisabled, switchHidden, switchOptedIn, switchOnClick } =
    props

  const getCreatorProfilePic = (event) => {
    if (!event) return null
    let ret = null
    const creatorUTE = event.userToEvents.find((userToEvent) => {
      return userToEvent.isCreator
    })
    if (creatorUTE.user.profilePic) {
      ret = `https://tippingpoint-staging-profile-pics.s3.us-east-2.amazonaws.com/${creatorUTE.user.id}`
    }
    return ret
  }

  return (
    <Stack
      direction={'row'}
      spacing={{ xs: 2 }}
      className={styles.EventHeaderContainer}
    >
      <Stack
        direction={'row'}
        spacing={{ xs: 2 }}
        className={styles.EventHeaderIconContainer}
      >
        {getCreatorProfilePic(event) ? (
          <img
            style={{
              width: '42px',
              height: '42px',
              borderRadius: '50%',
            }}
            src={getCreatorProfilePic(event)}
          />
        ) : (
          <AccountCircleIcon className={styles.EventHeaderIcon} />
        )}
        <Stack direction={'column'}>
          <div className={styles.EventHeader}>{event.name}</div>
          <div className={styles.EventSubheader}>
            {calculateHoursUntilDeadlineOccurs(event)} hours left
          </div>
        </Stack>
      </Stack>
      <div
        className={styles.EventSwitch}
        onClick={() => {
          if (!switchDisabled) switchOnClick(event)
        }}
      >
        {!switchHidden && (
          <CustomSwitch disabled={switchDisabled} optedIn={switchOptedIn} />
        )}
      </div>
    </Stack>
  )
}

const EventDetailsDisplayComponent = (props) => {
  const { header, value, icon } = props

  return (
    <Stack
      direction={'column'}
      spacing={{ xs: 1 }}
      className={styles.EventDetailsSubContainer}
    >
      <div className={styles.EventDetailsText}>{header}</div>
      <TextField
        className={styles.EventDetailsTextField}
        disabled={true}
        value={value}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': '#a2a3a9',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }}
      />
    </Stack>
  )
}

export const EventDetailsComponent = (props) => {
  const { event, currentEthAddress } = props

  if (event.eventType === EVENT_TYPES.DYNAMIC) {
    return (
      <Stack
        direction={'column'}
        spacing={{ xs: 2 }}
        className={styles.EventDetailsContainer}
      >
        <EventDetailsDisplayComponent
          header={'Campaign Category'}
          value={'Split Fixed Cost'}
          icon={<AppsIcon className={styles.EventDetailsIcon} />}
        />
        <EventDetailsDisplayComponent
          header={'Minimum People'}
          value={event.minimumPeople ? event.minimumPeople : 0}
          icon={<PersonIcon className={styles.EventDetailsIcon} />}
        />
        {event.maximumPeople && (
          <EventDetailsDisplayComponent
            header={'Maximum People'}
            value={event.maximumPeople}
            icon={<AccessibilityIcon className={styles.EventDetailsIcon} />}
          />
        )}
        <EventDetailsDisplayComponent
          header={'Goal Amount'}
          value={`$${convertIntTo2DecimalFloat(event.goalAmount)}`}
          icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
        />
        {!isCreator(event, currentEthAddress) ? (
          <EventDetailsDisplayComponent
            header={'Your Contribution'}
            value={`$${convertIntTo2DecimalFloat(
              getMyContributionAmount(event, currentEthAddress)
            )}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        ) : (
          <></>
        )}
      </Stack>
    )
  }
  if (event.eventType === EVENT_TYPES.STATIC) {
    return (
      <Stack
        direction={'column'}
        spacing={{ xs: 2 }}
        className={styles.EventDetailsContainer}
      >
        <EventDetailsDisplayComponent
          header={'Campaign Category'}
          value={'Fixed Price Per Person'}
          icon={<AppsIcon className={styles.EventDetailsIcon} />}
        />
        <EventDetailsDisplayComponent
          header={'Minimum People'}
          value={event.minimumPeople ? event.minimumPeople : 0}
          icon={<PersonIcon className={styles.EventDetailsIcon} />}
        />
        {event.maximumPeople && (
          <EventDetailsDisplayComponent
            header={'Maximum People'}
            value={event.maximumPeople ? event.maximumPeople : 0}
            icon={<AccessibilityIcon className={styles.EventDetailsIcon} />}
          />
        )}
        <EventDetailsDisplayComponent
          header={'Price per Person'}
          value={`$${convertIntTo2DecimalFloat(event.staticPricePerPerson)}`}
          icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
        />
        {!isCreator(event, currentEthAddress) ? (
          <EventDetailsDisplayComponent
            header={'Your Contribution'}
            value={`$${convertIntTo2DecimalFloat(
              getMyContributionAmount(event, currentEthAddress)
            )}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        ) : (
          <></>
        )}
      </Stack>
    )
  }
  if (event.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING) {
    return (
      <Stack
        direction={'column'}
        spacing={{ xs: 2 }}
        className={styles.EventDetailsContainer}
      >
        <EventDetailsDisplayComponent
          header={'Campaign Category'}
          value={'Make or Break'}
          icon={<AppsIcon className={styles.EventDetailsIcon} />}
        />
        <EventDetailsDisplayComponent
          header={'Goal Amount'}
          value={`$${convertIntTo2DecimalFloat(event.goalAmount)}`}
          icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
        />
        {!isCreator(event, currentEthAddress) ? (
          <EventDetailsDisplayComponent
            header={'Your Contribution'}
            value={`$${convertIntTo2DecimalFloat(
              getMyContributionAmount(event, currentEthAddress)
            )}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        ) : (
          <></>
        )}
      </Stack>
    )
  }
  if (event.eventType === EVENT_TYPES.GENERAL_FUNDRAISING) {
    return (
      <Stack
        direction={'column'}
        spacing={{ xs: 2 }}
        className={styles.EventDetailsContainer}
      >
        <EventDetailsDisplayComponent
          header={'Campaign Category'}
          value={'Anything Helps'}
          icon={<AppsIcon className={styles.EventDetailsIcon} />}
        />
        {!isCreator(event, currentEthAddress) ? (
          <EventDetailsDisplayComponent
            header={'Your Contribution'}
            value={`$${convertIntTo2DecimalFloat(
              getMyContributionAmount(event, currentEthAddress)
            )}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        ) : (
          <EventDetailsDisplayComponent
            header={'Total Money Raised'}
            value={`$${convertIntTo2DecimalFloat(
              eventService.getTotalAmountRaised(event)
            )}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        )}
      </Stack>
    )
  }
}

export const EventGoalProgressBar = (props) => {
  const { event } = props

  const currentAmountRaised = eventService.getTotalAmountRaised(event)
  const goalAmount = event.goalAmount
  const percentage =
    currentAmountRaised >= goalAmount
      ? 100
      : (currentAmountRaised / goalAmount) * 100
  const rightOffset =
    currentAmountRaised > goalAmount
      ? ((currentAmountRaised - goalAmount) / currentAmountRaised) * 100
      : 0

  return (
    <Stack
      direction={'column'}
      className={styles.EventGoalProgressBarContainer}
    >
      <Stack direction={'row'} className={styles.EventGoalProgressBarHeader}>
        <div className={styles.EventGoalProgressBarHeaderRaised}>Raised:</div>
        <div className={styles.EventGoalProgressBarHeaderGoal}>Goal:</div>
      </Stack>
      <Stack direction={'row'} className={styles.EventGoalProgressBarAmount}>
        <div className={styles.EventGoalProgressBarAmountRaised}>
          {`$${convertIntTo2DecimalFloat(currentAmountRaised)}`}
        </div>
        <div className={styles.EventGoalProgressBarAmountGoal}>
          {`$${convertIntTo2DecimalFloat(goalAmount)}`}
        </div>
      </Stack>
      <div className={styles.EventGoalProgressBarBackground}>
        <div
          style={{ right: `${rightOffset}%` }}
          className={styles.EventGoalProgressBarBackgroundBar}
        />
        <div
          style={{ width: `${percentage}%` }}
          className={styles.EventGoalProgressBarForeground}
        >
          <div className={styles.EventGoalProgressBarForegroundBar} />
        </div>
      </div>
    </Stack>
  )
}

export const DynamicEventProgressBar = (props) => {
  const { event } = props

  const goalAmount =
    event.eventType === EVENT_TYPES.DYNAMIC ||
    event.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING
      ? event.goalAmount
      : event.minimumPeople * event.staticPricePerPerson
  const currentAmountRaised = eventService.getTotalAmountRaised(event)
  const percentage = generateProgressBarValue(event)

  const countOptIns = () => {
    return event.userToEvents.filter((userToEvent) => userToEvent.optedIn)
      .length
  }

  const generateProgressWidth = () => {
    const optedInCount = countOptIns()
    if (event.maximumPeople) {
      if (optedInCount >= event.maximumPeople) {
        return 100
      }
      if (optedInCount <= event.minimumPeople) {
        return (optedInCount / event.minimumPeople) * 50
      }
      return (
        ((optedInCount - event.minimumPeople) /
          (event.maximumPeople - event.minimumPeople)) *
          50 +
        50
      )
    }
    if (optedInCount <= event.minimumPeople) {
      return (optedInCount / event.minimumPeople) * 100
    }
    return 100
  }

  const generateMinRightOffset = () => {
    const optedInCount = countOptIns()
    if (optedInCount <= event.minimumPeople) {
      return 0.2
    }
    return ((optedInCount - event.minimumPeople) / optedInCount) * 95 + 0.2
  }

  return (
    <Stack direction={'column'} width={'100%'} spacing={1}>
      <div className={styles.DynamicEventProgressBarHeader}>
        Opt-Ins:{' '}
        <span style={{ color: '#c1feaf', fontWeight: 600 }}>
          {countOptIns()} {countOptIns() !== 1 ? 'people' : 'person'}
        </span>
      </div>
      <div className={styles.DynamicEventProgressBarContainer}>
        <div className={styles.DynamicEventProgressBarBackground}>
          <div
            style={{ width: `${generateProgressWidth()}%` }}
            className={styles.DynamicEventProgressBarForeground}
          >
            <div className={styles.DynamicEventProgressBarForegroundBar} />
          </div>
        </div>
        <Stack
          direction={'column'}
          spacing={0.5}
          className={
            event.maximumPeople
              ? styles.DynamicEventProgressBarMinWithMax
              : styles.DynamicEventProgressBarMinNoMax
          }
          sx={
            !event.maximumPeople && {
              right: `${generateMinRightOffset()}%`,
            }
          }
        >
          <div className={styles.DynamicEventProgressBarMinText}>Min</div>
          <div className={styles.DynamicEventProgressBarMinBar} />
          <div className={styles.DynamicEventProgressBarMinAmount}>
            {event.minimumPeople}
          </div>
        </Stack>
        {event.maximumPeople && (
          <Stack
            direction={'column'}
            spacing={0.5}
            className={styles.DynamicEventProgressBarMax}
          >
            <div className={styles.DynamicEventProgressBarMaxText}>Max</div>
            <div className={styles.DynamicEventProgressBarMaxBar} />
            <div className={styles.DynamicEventProgressBarMaxAmount}>
              {event.maximumPeople}
            </div>
          </Stack>
        )}
      </div>
    </Stack>
  )
}

const AttendeeListItem = (props) => {
  const { attendee } = props

  return (
    <Stack
      direction={'row'}
      spacing={2}
      className={styles.EventAttendingListItem}
    >
      <Stack
        direction={'row'}
        className={styles.EventAttendingListItemContainer}
        spacing={2}
      >
        {attendee.profilePic ? (
          <img
            src={`https://tippingpoint-staging-profile-pics.s3.us-east-2.amazonaws.com/${attendee.user.id}`}
            className={styles.EventAttendingListItemImage}
            alt=""
          />
        ) : (
          <AccountCircleIcon
            className={styles.EventAttendingListItemImage}
            style={{ width: '42px', height: '42px' }}
          />
        )}
        <div
          className={styles.EventAttendingListItemName}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>
            {attendee.displayName}{' '}
            {`(${attendee.ethAddress.slice(0, 6)}...${attendee.ethAddress.slice(
              -3
            )})`}
          </span>
          <span>{attendee.optedIn ? 'Attending' : 'Not Attending'}</span>
          <span>
            {attendee.optedIn
              ? `$${convertIntTo2DecimalFloat(attendee.currentContribution)}`
              : '$0'}
          </span>
        </div>
      </Stack>
      <div className={styles.EventAttendingListItemAddr}></div>
    </Stack>
  )
}

export const EventAttendingList = (props) => {
  const { attendees } = props

  const [expanded, setExpanded] = React.useState(false)

  return (
    <Stack
      direction={'column'}
      spacing={1}
      className={styles.EventAttendingContainer}
    >
      <div className={styles.EventAttendingHeader}>Invitees</div>
      <Accordion
        className={styles.EventAttendingListContainer}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        {!expanded && (
          <AccordionSummary>
            <Stack direction={'column'} style={{ width: '100%' }} spacing={1}>
              <Stack
                direction={'column'}
                className={styles.EventAttendeesAccordionHeaderList}
                spacing={1}
              >
                {attendees.slice(0, 1).map((attendee) => {
                  return <AttendeeListItem attendee={attendee} />
                })}
              </Stack>
              {attendees.length > 1 && (
                <Divider
                  style={{
                    width: '100%',
                    backgroundColor: '#a2a3a9',
                  }}
                />
              )}
              {attendees.length > 1 && (
                <Stack
                  direction={'row'}
                  className={styles.EventAttendeesAccordionHeader}
                  justifyContent={{ xs: 'space-between' }}
                  spacing={{ xs: 20 }}
                >
                  <div className={styles.EventAttendeesAccordionHeaderText}>
                    See More
                  </div>
                  <div
                    className={styles.EventAttendeesAccordionOpenButton}
                    onClick={() => setExpanded(true)}
                  >
                    OPEN
                  </div>
                </Stack>
              )}
            </Stack>
          </AccordionSummary>
        )}
        <AccordionDetails>
          <Stack
            direction={'column'}
            spacing={1}
            className={styles.EventAttendingList}
          >
            {attendees.slice(0, 30).map((attendee) => {
              return <AttendeeListItem attendee={attendee} />
            })}
            <div
              className={styles.EventAttendeesAccordionDetailsCloseButton}
              onClick={() => setExpanded(false)}
            >
              CLOSE
            </div>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}

export const EventPage = (props) => {
  const { currentEthAddress, userInfo } = props
  let { eventId } = useParams()
  const [selectedEvent, setSelectedEvent] = useState(null)

  const { getProvider } = useWeb3Provider()

  const handleInsufficientFunds = () => {
    setInsufficientFundsSnackbarOpen(true)
  }

  const getMyOptIn = (event) => {
    if (!currentEthAddress) {
      return
    }
    const currentUserToEvent = event?.userToEvents.find(
      (userToEvent) => userToEvent.user.ethAddress === currentEthAddress
    )
    if (currentUserToEvent?.isCreator) return true
    return currentUserToEvent ? currentUserToEvent.optedIn : false
  }

  async function getSelectedEvent() {
    await eventService
      .getEvent(eventId, currentEthAddress)
      .then((event) => {
        console.log('getSelectedEvent', { event })
        if (new Date(event.deadline) < new Date()) {
          navigate('/pastCampaign/' + eventId)
        }
        setSelectedEvent(event)
        const userOnEvent = event.userToEvents.find((userToEvent) => {
          return userToEvent.user.ethAddress === currentEthAddress
        })
        console.log('userOnEvent found --', { userOnEvent })
        if (userOnEvent) {
          setOptIn(userOnEvent.optedIn)
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setSelectedEvent(undefined)
        }
      })
  }

  useEffect(() => {
    getSelectedEvent()
  }, [])

  const [progress, setProgress] = React.useState(null)

  const saveOptInDecision = async (contributionAmt, autoOptOutTarget) => {
    try {
      const optInResult = await web3.saveInviteeUserDecision(
        currentEthAddress,
        contributionAmt,
        autoOptOutTarget,
        selectedEvent.deadline,
        selectedEvent?.creatorEthAddress,
        optIn,
        selectedEvent.id,
        await getProvider(),
        setProgress
      )
      const optInResultReturned =
        optInResult !== null && optInResult !== undefined
      if (optInResultReturned) {
        console.log(
          'optInResultReturned, setting selected event',
          optInResultReturned
        )
        getSelectedEvent()
      }
      setTimeout(() => {
        setOptInModal(false)
        setProgress(null)
      }, 1000)
    } catch (error) {
      console.log('error saving opt in decision', error.message)
      if (error.message === 'Insufficient USDC tokens') {
        handleInsufficientFunds()
      }
    }
  }

  const setEvent = (event) => {
    setSelectedEvent(event)
  }

  const getAttendingList = () => {
    return selectedEvent.userToEvents
      .filter(
        (userToEvent) =>
          userToEvent.optedIn ||
          (selectedEvent.isCreatorAttendee && userToEvent.isCreator)
      )
      .map((userToEvent) => ({
        ...userToEvent.user,
        ...userToEvent,
      }))
  }

  const renderCreatorName = (event) => {
    if (!event) return ''
    const creator = event.userToEvents.find(
      (userToEvent) => userToEvent.isCreator
    )
    return creator
      ? `${creator.user.displayName} (${creator.user.ethAddress})`
      : ''
  }

  const [openOptInModal, setOptInModal] = React.useState(false)
  const [optIn, setOptIn] = React.useState(null)
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false)
  const [imageModalOpen, setImageModalOpen] = React.useState(false)
  const [youtubeModalOpen, setYoutubeModalOpen] = React.useState(false)
  const [editImageValue, setEditImageValue] = React.useState({})
  const [editingActive, setEditingActive] = React.useState(false)
  const [editingEvent, setEditingEvent] = React.useState('')
  const [editingValue, setEditingValue] = React.useState('')
  const [editingSnackbarOpen, setEditingSnackbarOpen] = React.useState(false)
  const [editingSubmitSuccess, setEditingSubmitSuccess] = React.useState(false)
  const [insufficientFundsSnackbarOpen, setInsufficientFundsSnackbarOpen] =
    React.useState(false)
  const navigate = useNavigate()

  return (
    // <Box sx={{ width: '100%', borderColor: 'black' }}>
    //   {selectedEvent === undefined ? (
    //     <>
    //       <h1>404 Not Found :( </h1>
    //     </>
    //   ) : (
    //     <EventInfo
    //       selectedEvent={selectedEvent}
    //       optedIn={optedIn}
    //       setOptedIn={setOptedIn}
    //       currentEthAddress={currentEthAddress}
    //       updateSelectedEvent={setSelectedEvent}
    //     />
    //   )}
    // </Box>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {selectedEvent && (
        <>
          <Stack
            direction={'column'}
            spacing={{ xs: 2 }}
            alignItems={{ xs: 'center' }}
            className={styles.EventContainer}
          >
            <EventHeaderComponent
              event={selectedEvent}
              switchDisabled={
                isCreator(selectedEvent, currentEthAddress) ||
                new Date(selectedEvent.deadline) < new Date()
              }
              switchOptedIn={getMyOptIn(selectedEvent)}
              switchOnClick={() => {
                // not sure what to put here
                setOptIn(!getMyOptIn(selectedEvent))
                setOptInModal(true)
              }}
            />
            {/* Event Image */}
            <div
              className={styles.EventImageContainer}
              style={!selectedEvent.file ? { width: '80%' } : {}}
            >
              {selectedEvent.file ? (
                <img
                  src={selectedEvent.imgUrl}
                  alt={selectedEvent.name}
                  className={styles.EventImage}
                />
              ) : (
                <ImageIcon className={styles.EventImageIcon} />
              )}
              {selectedEvent.youtubeLink ? (
                <>
                  <iframe
                    width="560"
                    height="315"
                    src={
                      'https://www.youtube.com/embed/' +
                      selectedEvent.youtubeLink
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </>
              ) : (
                <></>
              )}
            </div>
            {editingActive && (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="text"
                  sx={{
                    color: '#829aff',
                    textTransform: 'capitalize',
                    border: '2px solid #a2a3a9',
                    borderRadius: '4px',
                  }}
                  onClick={() => {
                    setEditingEvent('youtubeLink')
                    setEditingValue(selectedEvent.youtubeLink)
                    setYoutubeModalOpen(true)
                  }}
                >
                  Edit YouTube Link
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: '#829aff',
                    textTransform: 'capitalize',
                    border: '2px solid #a2a3a9',
                    borderRadius: '4px',
                  }}
                  onClick={() => {
                    setEditImageValue({
                      name: 'PDF, JPEG or PNG less than 5MB',
                    })
                    setImageModalOpen(true)
                  }}
                >
                  Edit Image
                </Button>
              </Stack>
            )}
            {/* {selectedEvent.file && <img src={selectedEvent.file} alt={selectedEvent.name} className={styles.eventImage} />} */}
            {/* <Stack direction={'row'} className={styles.EventLinkContainer}>
              <div className={styles.EventLinkText}>
                {generateEventLink(selectedEvent.id).substring(0, 30) + '...'}
              </div>
              <ContentCopyIcon
                className={styles.EventLinkCopyIcon}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    generateEventLink(selectedEvent.id)
                  )
                  setClipboardOpen(true)
                  setTimeout(() => {
                    setClipboardOpen(false)
                  }, 4000)
                }}
              />
              {clipboardOpen && (
                <div className={styles.EventLinkCopyText}>
                  Copied to clipboard
                </div>
              )}
            </Stack> */}
            <FormattedEventUrl
              event={selectedEvent}
              containerClassName={styles.EventLinkContainer}
            />
            <Stack
              direction={'column'}
              className={styles.EventDescriptionContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventDescriptionText}>Creator</div>
              <TextField
                className={styles.CreatorNameTextField}
                disabled={!editingActive}
                value={renderCreatorName(selectedEvent)}
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            {(selectedEvent.eventType === EVENT_TYPES.DYNAMIC ||
              selectedEvent.eventType === EVENT_TYPES.STATIC) && (
              <DynamicEventProgressBar event={selectedEvent} />
            )}
            {(selectedEvent.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING ||
              (selectedEvent.eventType === EVENT_TYPES.GENERAL_FUNDRAISING &&
                selectedEvent.goalAmount)) && (
              <EventGoalProgressBar event={selectedEvent} />
            )}
            {getAttendingList().length > 0 && (
              <EventAttendingList attendees={getAttendingList()} />
            )}
            <Stack
              direction={'column'}
              className={styles.EventNameContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventNameText}>Campaign Name</div>

              {/* <input
                className={
                  editingActive ? styles.EventNameTextFieldEditing : styles.EventNameTextField
                }
                value={selectedEvent.name}
                disabled={!editingActive}
                onClick={() => {
                  if (editingActive) {
                    console.log('editing event name')
                    setEditingEvent('name')
                    setEditingValue(selectedEvent.name)
                  }
                }}
                
              /> */}
              <TextField
                className={styles.EventNameTextField}
                value={selectedEvent.name}
                disabled={!editingActive}
                onClick={() => {
                  if (editingActive) {
                    console.log('editing Campaign name')
                    setEditingEvent('name')
                    setEditingValue(selectedEvent.name)
                  }
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            <Stack
              direction={'column'}
              className={styles.EventDescriptionContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventDescriptionText}>Description</div>
              <TextField
                className={styles.EventDescriptionTextField}
                disabled={!editingActive}
                value={selectedEvent.description}
                onClick={() => {
                  if (editingActive) {
                    console.log('editing event description')
                    setEditingEvent('description')
                    setEditingValue(selectedEvent.description)
                  }
                }}
                multiline
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            <EventDetailsComponent
              event={selectedEvent}
              currentEthAddress={currentEthAddress}
            />
            {/* <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventPreferredCurrencyContainer}
            >
              <div className={styles.EventDetailsText}>
                {'Preferred Currency'}
              </div>
              <TextField
                // className={styles.EventPreferredCurrencyTextField}
                disabled={true}
                variant="outlined"
                value={'USDC'}
                // disabling the text field causes styles to be overridden
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {<AttachMoneyIcon className={styles.EventDetailsIcon} />}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack> */}
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventDeadlineContainer}
            >
              <div className={styles.EventDeadlineText}>Deadline</div>
              <TextField
                // className={styles.EventDeadlineTextField}
                disabled={true}
                value={getPrettyDateString(new Date(selectedEvent.deadline))}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon className={styles.EventDetailsIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            {isCreator(selectedEvent, currentEthAddress) ? (
              <>
                {/* <Stack
                  direction={'row'}
                  className={styles.EventCheckboxContainer}
                >
                  <Checkbox
                    className={styles.EventCheckbox}
                    checked={false}
                    sx={{ '& .MuiCheckbox-root': { color: '#a2a3a9' } }}
                  />
                  <div className={styles.EventCheckboxText}>
                    Limit invites to this guest list only
                  </div>
                </Stack>
                <Stack
                  direction={'row'}
                  className={styles.EventCheckboxContainer}
                >
                  <Checkbox className={styles.EventCheckbox} checked={false} />
                  <div className={styles.EventCheckboxText}>
                    Recurring Event
                  </div>
                </Stack> */}
                <Stack
                  direction={'row'}
                  spacing={{ xs: 2 }}
                  className={styles.EventCancelEditContainer}
                >
                  <Button
                    className={styles.EventCancelButton}
                    variant="outlined"
                    onClick={async () => {
                      setCancelModalOpen(true)
                      // const eventCallback = (_event) => {
                      //   navigate('/active', {state: {eventCanceled: true}})
                      // }
                      // await eventService.cancelEvent(
                      //   selectedEvent.id,
                      //   currentEthAddress,
                      //   eventCallback
                      // )
                    }}
                  >
                    Cancel Campaign
                  </Button>
                  <Button
                    className={styles.EventEditButton}
                    variant="outlined"
                    onClick={() => {
                      if (!editingActive) {
                        setEditingActive(true)
                        window.scrollTo({ top: 70, behavior: 'smooth' })
                      } else {
                        setEditingActive(false)
                      }
                    }}
                  >
                    {editingActive ? 'Done' : 'Edit Campaign'}
                  </Button>
                </Stack>
                <Modal
                  open={imageModalOpen}
                  onClose={() => setCancelModalOpen(false)}
                >
                  <Stack
                    direction={'column'}
                    className={styles.EditEvent}
                    spacing={2}
                  >
                    <div className={styles.EditEventHeader}>Image File</div>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <div
                        style={{
                          color: '#c1feaf',
                          fontSize: '16px',
                        }}
                      >
                        {editImageValue.name}
                      </div>
                      <input
                        accept="image/*"
                        // className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        onChange={(evt) => {
                          setEditImageValue(evt.target.files[0])
                        }}
                        type="file"
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{
                            textTransform: 'capitalize',
                            color: '#a2a3a9',
                            borderColor: '#a2a3a9',
                            borderRadius: '15px',
                            padding: '0px 0px',
                          }}
                        >
                          Upload
                        </Button>
                      </label>
                    </Stack>
                    <Stack
                      spacing={6}
                      direction="row"
                      className={styles.EditEventButtonContainer}
                    >
                      <Button
                        onClick={() => {
                          setImageModalOpen(false)
                          setEditingActive(true)
                        }}
                        variant="outlined"
                        className={styles.EditEventNoButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          if (!editImageValue) {
                            console.log('empty value')
                            setEditingSnackbarOpen(true)
                            setEditingSubmitSuccess(false)
                            return
                          }
                          fetch(BACKEND_URL + '/event/editEventImg', {
                            method: 'PUT',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              creatorEthAddress:
                                selectedEvent.creatorEthAddress,
                              eventId: selectedEvent.id,
                            }),
                            credentials: 'include',
                          })
                            .then(async (res) => {
                              const { presignedUrl } = await res.json()

                              fetch(presignedUrl, {
                                method: 'PUT',
                                headers: {
                                  'Content-Type': 'multipart/form-data',
                                },
                                body: editImageValue,
                              })
                                .then((response) => {
                                  console.log('Upload successful')
                                  setEditingSnackbarOpen(true)
                                  setEditingSubmitSuccess(true)
                                  setEditImageValue({})
                                  setEditingActive(false)
                                  setImageModalOpen(false)
                                })
                                .catch((error) => {
                                  console.error('Error uploading file:', error)
                                  setEditingSnackbarOpen(true)
                                  setEditingSubmitSuccess(false)
                                })
                            })
                            .catch((err) => {
                              setEditingSnackbarOpen(true)
                              setEditingSubmitSuccess(false)
                            })
                        }}
                        variant="outlined"
                        className={styles.EditEventYesButton}
                      >
                        Confirm
                      </Button>
                    </Stack>
                  </Stack>
                </Modal>
                <Modal
                  open={cancelModalOpen}
                  onClose={() => setCancelModalOpen(false)}
                  // sx={{
                  //   '& .MuiModal-backdrop': {
                  //     width: '351px !important',
                  //     left: '50% !important',
                  //     top: '52% !important',
                  //     height: '400px !important',
                  //     transform: 'translate(-50%, -50%) !important',
                  //     backgroundColor: '#080808 !important',
                  //     opacity: '0.9 !important',
                  //     borderRadius: '12px !important',
                  //   },
                  //   width: '100% !important',
                  // }}
                >
                  <Stack direction={'column'} className={styles.CancelModal}>
                    <div className={styles.CancelModalHeader}>
                      Are you sure you want to cancel this Campaign?
                    </div>
                    <div className={styles.CancelModalText}>
                      This action cannot be undone.
                    </div>
                    <Stack
                      direction="row"
                      className={styles.CancelModalButtonContainer}
                    >
                      <Button
                        onClick={() => setCancelModalOpen(false)}
                        variant="outlined"
                        className={styles.CancelModalNoButton}
                      >
                        No
                      </Button>
                      <Button
                        onClick={async () => {
                          console.log(
                            'cancel event',
                            currentEthAddress,
                            selectedEvent.creatorEthAddress
                          )
                          const eventCallback = (_event) => {
                            navigate('/active', {
                              state: { eventCanceled: true },
                            })
                          }
                          await eventService.cancelEvent(
                            selectedEvent.id,
                            currentEthAddress,
                            eventCallback
                          )
                        }}
                        variant="outlined"
                        className={styles.CancelModalYesButton}
                      >
                        Yes
                      </Button>
                    </Stack>
                  </Stack>
                </Modal>
                <Modal
                  open={!!editingEvent && editingEvent !== 'youtubeLink'}
                  onClose={() => {
                    setEditingEvent('')
                    setEditingActive(false)
                  }}
                >
                  <Stack
                    direction={'column'}
                    className={styles.EditEvent}
                    spacing={2}
                  >
                    <div className={styles.EditEventHeader}>Text Input</div>
                    <div className={styles.EditEventText}>
                      {editingEvent === 'name'
                        ? 'Campaign Name'
                        : 'Campaign Description'}
                    </div>
                    <TextField
                      required
                      multiline={editingEvent === 'description'}
                      autoComplete="off"
                      error={editingValue === ''}
                      helperText={
                        editingValue === ''
                          ? 'Event ' + editingEvent + ' is required'
                          : null
                      }
                      placeholder={
                        editingEvent === 'name'
                          ? 'Campaign Name'
                          : 'Campaign Description'
                      }
                      value={editingValue}
                      onChange={(evt) => setEditingValue(evt.target.value)}
                      sx={{
                        '& .MuiInputBase-root': {
                          color: '#a2a3a9',
                        },
                        '& .MuiInputBase-input': {
                          '-webkit-text-fill-color': '#829aff',
                          // border: '1px solid #a2a3a9 !important',
                        },
                        '& input::placeholder': {
                          color: '#cac4d0',
                          opacity: 1,
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#434343',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '3px',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: '#d32f2f',
                          },
                        },
                        width: '100%',
                      }}
                    />
                    <Stack
                      spacing={6}
                      direction="row"
                      className={styles.EditEventButtonContainer}
                    >
                      <Button
                        onClick={() => {
                          setEditingActive(true)
                          setEditingEvent('')
                        }}
                        variant="outlined"
                        className={styles.EditEventNoButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          if (editingValue === '') {
                            console.log('empty value')
                            setEditingSnackbarOpen(true)
                            setEditingSubmitSuccess(false)
                            return
                          }
                          const updatedEvent = {
                            ...selectedEvent,
                            [editingEvent]: editingValue,
                          }
                          fetch(BACKEND_URL + '/event/editEvent', {
                            method: 'PUT',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              event: {
                                ...updatedEvent,
                                expectedContributionAmount: undefined,
                              },
                            }),
                            credentials: 'include',
                          })
                            .then((res) => {
                              console.log('edit event response', { res })
                              setEditingSnackbarOpen(true)
                              setEditingSubmitSuccess(true)
                              setSelectedEvent(updatedEvent)
                              setEditingActive(false)
                              setEditingEvent('')
                            })
                            .catch((err) => {
                              console.log('edit event error', updatedEvent)
                              setEditingSnackbarOpen(true)
                              setEditingSubmitSuccess(false)
                            })
                        }}
                        variant="outlined"
                        className={styles.EditEventYesButton}
                      >
                        Confirm
                      </Button>
                    </Stack>
                  </Stack>
                </Modal>

                <Modal
                  open={youtubeModalOpen}
                  onClose={() => setYoutubeModalOpen(false)}
                >
                  <Stack
                    direction={'column'}
                    className={styles.EditEvent}
                    spacing={2}
                  >
                    <div className={styles.EditEventHeader}>Youtube URL</div>
                    <div className={styles.EditEventText}>Youtube URL</div>
                    <TextField
                      required
                      autoComplete="off"
                      error={editingValue === ''}
                      helperText={
                        editingValue === '' ? 'Youtube URL is required' : null
                      }
                      placeholder={'Youtube URL'}
                      // value={editingValue}
                      onChange={(evt) => {
                        if (evt.target.value === '') {
                          setEditingValue('')
                          return
                        }
                        setEditingValue(evt.target.value)
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          color: '#a2a3a9',
                        },
                        '& .MuiInputBase-input': {
                          '-webkit-text-fill-color': '#829aff',
                          // border: '1px solid #a2a3a9 !important',
                        },
                        '& input::placeholder': {
                          color: '#cac4d0',
                          opacity: 1,
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#434343',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '3px',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: '#d32f2f',
                          },
                        },
                        width: '100%',
                      }}
                    />
                    <Stack
                      spacing={6}
                      direction="row"
                      className={styles.EditEventButtonContainer}
                    >
                      <Button
                        onClick={() => {
                          setYoutubeModalOpen(false)
                          setEditingActive(true)
                        }}
                        variant="outlined"
                        className={styles.EditEventNoButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          if (editingValue === '') {
                            console.log('empty value')
                            setEditingSnackbarOpen(true)
                            setEditingSubmitSuccess(false)
                            return
                          }
                          const ytVideoCode = matchYoutubeURL(editingValue)
                          const updatedEvent = {
                            ...selectedEvent,
                            [editingEvent]: ytVideoCode,
                          }
                          fetch(BACKEND_URL + '/event/editEvent', {
                            method: 'PUT',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              event: {
                                ...updatedEvent,
                                expectedContributionAmount: undefined,
                              },
                            }),
                            credentials: 'include',
                          })
                            .then(async (res) => {
                              setEditingSnackbarOpen(true)
                              setEditingSubmitSuccess(true)
                              setYoutubeModalOpen(false)
                              setSelectedEvent(updatedEvent)
                              setEditingActive(false)
                              setEditingEvent('')
                            })
                            .catch((err) => {
                              setEditingSnackbarOpen(true)
                              setEditingSubmitSuccess(false)
                            })
                        }}
                        variant="outlined"
                        className={styles.EditEventYesButton}
                      >
                        Confirm
                      </Button>
                    </Stack>
                  </Stack>
                </Modal>
              </>
            ) : (
              <Button
                className={styles.EventOptInButton}
                onClick={() => {
                  setOptIn(!getMyOptIn(selectedEvent))
                  setOptInModal(true)
                }}
              >
                {getMyOptIn(selectedEvent) ? 'Opt-Out' : 'Opt-In'}
              </Button>
            )}
          </Stack>
          {selectedEvent && (
            <OptInModal
              event={selectedEvent}
              setEvent={setEvent}
              userInfo={userInfo}
              optIn={optIn}
              openOptInModal={openOptInModal}
              onClickCancel={() => setOptInModal(false)}
              onClickConfirm={saveOptInDecision}
              progress={progress}
            />
          )}
          <Snackbar
            open={editingSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => setEditingSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setEditingSnackbarOpen(false)}
              severity={editingSubmitSuccess ? 'success' : 'error'}
            >
              {editingSubmitSuccess
                ? 'Campaign successfully updated!'
                : 'Error updating Campaign. Please try again.'}
            </Alert>
          </Snackbar>
          <Snackbar
            open={insufficientFundsSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => setInsufficientFundsSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setInsufficientFundsSnackbarOpen(false)}
              severity={'error'}
            >
              Insufficient USDC tokens
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  )
}
